// Dependencies
import { createPortal } from 'react-dom';
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { faBars, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Api
import { fetcher } from "../Api";

// Components
import Input from "../Components/Input";

// Stores
import useAuthStore from "../Stores/authStore";

// Styles
import css from './Navigation.sass';

const hamburgerElement = document.createElement('div');
document.body.appendChild(hamburgerElement);

export const HumburgerButton = (props: { isOpen: boolean, onClick: () => void }) => {
    const { isOpen, onClick } = props;

    return createPortal(
        <div onClick={onClick} className={css.hamburgerButton}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </div>,
        hamburgerElement,
    );
}

const Navigation = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [logout] = useAuthStore(state => [state.logout]);

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    const handleLogout = async () => {
        const res = await fetcher(`/logout`);
        logout();
    };

    const toggleNavigationState = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className={`${css.Main} ${isOpen ? css.isOpen : ''}`}>
            <div className={css.content}>

                <NavLink exact={true} activeClassName={css.isActive} to="/" className={css.item}>
                    <div>Journal</div>
                </NavLink>

                <NavLink exact={true} activeClassName={css.isActive} to="/write" className={css.item}>
                    <div>Add Entry</div>
                </NavLink>

                <NavLink exact={true} activeClassName={css.isActive} to="/deleted" className={css.item}>
                    <div>View Deleted</div>
                </NavLink>

                <NavLink exact={true} activeClassName={css.isActive} to="/workspaces/personal" className={css.item}>
                    <div>Personal Workspace</div>
                </NavLink>

                {/* <NavLink exact={true} activeClassName={css.isActive} to="/branding" className={css.item}>
                    <div>Branding</div>
                </NavLink> */}

                {/* <div>
                    <Input value="" onChange={handleSearch} />
                </div> */}
            </div>

            <HumburgerButton isOpen={isOpen} onClick={toggleNavigationState} />
        </div>
    );
}

export default Navigation;