// Dependencies
import useSWR  from 'swr';
import { useHistory } from 'react-router-dom';
import { Menu, MenuItem } from '@mantine/core';
import { parse, format, startOfDay, subYears } from 'date-fns';
import React, { useEffect, useState, useRef } from 'react';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faTrash, faPencilAlt } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Api
import { fetcher } from '../../Api';

// Components
import Scrubber from './Scrubber';
import LightBox from './Lightbox';
import Input from '../../Components/Input';
import Button from '../../Components/Button';
import Modal, { PaddedContent, BottomRowContent } from '../../Components/Modal';

// Hooks
import useDebounce from '../../Hooks/useDebounce';

// Utils
import serialize from '../../utils/slate-serialize';

// Styles
import css from './Home.sass';

declare let API_DOMAIN: string;

interface DateGroup {
    dayStart: Date,
    groupKey: string,
    entries: any[],
}

const JournalEntries = (props: { entries: any[], refreshEntries: () => void }) => {
    const { entries, refreshEntries } = props;

    const history = useHistory();
    const [modal, setModal] = useState(null);
    const [lightboxState, setLightboxState] = useState(null);

    const handleEdit = (id: string) => {
        history.push(`/edit/${id}/`);
    };

    const handleRemove = (id: string) => {
        setModal({ entryID: id, isRequesting: false });
    };

    const deleteEntry = async (entryID: string) => {
        setModal({ ...modal, isRequesting: true });
        await fetcher(`/api/journal/entry/${entryID}`, { method: 'DELETE' });
        refreshEntries();
        setModal(null);
    };

    const dateGroups: { [name: string]: DateGroup } = {};

    // parse data
    entries.forEach((entry: any, ii: number) => {
        const serialized = serialize(entry.entry);
        const dateGroupKey = entry.localDate.slice(0, 10);
        const localDate = parse(entry.localDate, 'yyyy-MM-dd-HH:mm', new Date());

        const parsedEntry = {
            ...entry,
            localDate,
            serialized,
            key: ii,
        };

        if(!dateGroups[dateGroupKey]) {
            dateGroups[dateGroupKey] = {
                entries:[],
                groupKey: dateGroupKey,
                dayStart: startOfDay(localDate),
            }
        }

        dateGroups[dateGroupKey].entries.push(parsedEntry);
    });

    const sortedEntries = Object.keys(dateGroups).map((groupKey: string) => {
        return dateGroups[groupKey];
    }).sort((a, b) => b.dayStart.getTime() - a.dayStart.getTime());

    sortedEntries.forEach(group => {
        group.entries.sort((a, b) => {
            return b.localDate.getTime() - a.localDate.getTime();
        });
    });

    return (
        <div className={css.entries}>
            {sortedEntries.map((entry) => {

                return (
                    <div className={css.journalEntry} key={entry.groupKey}>
                        <div className={css.dateGroup}>
                            <div className={css.groupDateLabel}>{format(entry.dayStart, 'EEEE. MMM d, yyyy')}</div>

                            <div className={css.entries}>
                                {entry.entries.map((entry: any) => {
                                    const images = entry.images;

                                    const handleImageClick = (imageID: string) => {
                                        setLightboxState({ selected: imageID, gallery: images.map((im: any) => im._id) });
                                    };

                                    return (
                                        <div key={entry.key} className={css.content}>

                                            <div className={css.entryDate}>
                                                <span>{format(entry.localDate, 'h:mm aaaa')}</span>
                                            </div>

                                            <div className={`${css.textContent}`}>
                                                <div className={css.RichTextContainer}>
                                                    {entry.serialized}
                                                </div>

                                                {images.length > 0 ? (
                                                    <div className={css.imagesContainer}>
                                                        <div>
                                                            <div>
                                                                {images.map((image: any) => {
                                                                    return (
                                                                        <div onClick={() => handleImageClick(image._id)} className={css.imagePolaroid} key={image._id}>
                                                                            <img loading="lazy" src={`${API_DOMAIN}/images/${image._id}`} />
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>

                                            <div className={css.menuContainer}>
                                                <Menu menuPosition={{ top: 0, right: 0 }}>
                                                    <MenuItem onClick={() => handleEdit(entry._id)} icon={<FontAwesomeIcon icon={faPencilAlt} />}>Edit</MenuItem>
                                                    <MenuItem onClick={() => handleRemove(entry._id)} icon={<FontAwesomeIcon icon={faTrash} />}>Remove</MenuItem>
                                                </Menu>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                );
            })}

            {lightboxState ? (
                <LightBox onClose={() => setLightboxState(null)} imageID={lightboxState.selected} imageGallery={lightboxState.gallery} />
            ) : null}

            {modal ? (
                <Modal title="Confirm Delete" onClose={() => setModal(null)}>
                    <PaddedContent>
                        <div>Do you really want to remove this post? It will be gone forever.</div>
                    </PaddedContent>

                    <BottomRowContent>
                        <Button onClick={() => setModal(null)} color="neutral" emphasis="slight">Cancel</Button>
                        <Button isLoading={modal.isRequesting} onClick={() => deleteEntry(modal.entryID)} color="destructive" emphasis="high">Delete Entry</Button>
                    </BottomRowContent>
                </Modal>
            ) : null}
        </div>
    );
}

const Search = (props: { onSearch: (search: string) => void }) => {
    const { onSearch } = props;

    const inputRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchTerm = useDebounce(searchValue, 500);

    const handleChange = (value: string) => {
        setSearchValue(value);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        const { key } = event;

        if(key === 'k' && event.ctrlKey) {
            event.preventDefault();
            inputRef.current.focus();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    useEffect(() => {
        onSearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    const rightSection = (
        <div className={css.inputRightSide}>
            <kbd>Ctrl</kbd> + <kbd>K</kbd>
        </div>
    );
    
    return (
        <React.Fragment>
            <div />

            <Input
                value={searchValue}
                ref={inputRef}
                onChange={handleChange}
                rightSectionWidth={90}
                rightSection={rightSection}
                className={css.searchInput}
                icon={<FontAwesomeIcon icon={faSearch} />}
            />
        </React.Fragment>
    );
}

const Home = () => {
    const showScrubber = false;
    const [searchString, setSearchString] = useState('');

    const { data, mutate } = useSWR(`/api/journal/entries?search=${searchString}`);
    const journalEntries = data?.entries ?? [];

    const handleSearch = (search: string) => {
        setSearchString(search);
    };

    return (
        <div className={css.main}>
            <div className={css.searchWrapper}>
                <Search onSearch={handleSearch} />
            </div>

            <div className={css.journalEntriesWrapper}>
                {data ? <JournalEntries refreshEntries={mutate} entries={journalEntries} /> : null}
            </div>

            {showScrubber ? (
                <Scrubber startDate={subYears(new Date(), 1)} endDate={new Date()} />
            ) : null}
        </div>
    );
}

export default Home;