// Dependencies
import React, { ReactElement } from "react";
import { Text } from 'slate';
import escapeHtml from 'escape-html';

type SlateNodeChild = {
    text: string,
    bold?: boolean,
    italic?: boolean,
    underline?: boolean,
    code?: boolean,
}

type SlateNode = {
    type: string,
    children: SlateNodeChild[],
}

/**
 * Serialize slate JSON to html.
 */
const serialize = (nodes: SlateNode[]) => {
    return nodes.map((node, ii) => {
        return serializeNode(node, ii);
    });
}

const serializeNode = (node: SlateNode | SlateNodeChild, key: any): ReactElement => {
    if (Text.isText(node)) {
        let content: (string | ReactElement) = node.text;

        if (node.bold) {
            content = <strong>{content}</strong>
        }

        if (node.italic) {
            content = <em>{content}</em>
        }

        if (node.underline) {
            content = <u>{content}</u>
        }

        if (node.code) {
            content = <code>{content}</code>
        }

        return <span key={key}>{content}</span>
    }
    
    const children = node.children.map((n, ii) => serializeNode(n, ii));

    switch (node.type) {
        case 'block-quote': {
            return <blockquote key={key}><p>{children}</p></blockquote>
        }
        case 'paragraph': {
            return <p key={key}>{children}</p>
        }
        case 'numbered-list': {
            return <ol key={key}>{children}</ol>
        }
        case 'bulleted-list': {
            return <ol key={key}>{children}</ol>
        }
        case 'list-item': {
            return <li key={key}>{children}</li>
        }
        case 'heading-one': {
            return <h1 key={key}>{children}</h1>
        }
        case 'heading-two': {
            return <h2 key={key}>{children}</h2>
        }
        default: {
            return <div key={key}>{children}</div>;
        }
    }
}

export default serialize;