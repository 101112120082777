// utils
const isPlainObject = (value: any): boolean => {
    return typeof value === 'object' && value !== null;
}

/**
 * This will safely access nested values in an object.
 * This was made a little complicated to handle falsy values.
 *
 * @param {array|string} keyPath - path of properties to access
 * @param {object} obj - object we will be accessing
 * @param {any} notSetValue - the value to return if the keyPath is invalid
 */

const safelyAccessNestedValues = (nestedObj: any, path: (string | number)[], notSetValue?: any): any => {
    const keyPath = typeof path === 'string' ? [path] : path;

    // get a new keyPath with the last item removed
    const transformedList = keyPath.slice(0, keyPath.length - 1);

    // gets the object before the final key
    const shallowObj = transformedList.reduce((xs, x) => ((xs && xs[x]) ? xs[x] : {}), nestedObj);

    // set the return value to the notSetValue ( will overwrite later )
    let value = notSetValue;

    try {
        const lastKey = keyPath.slice(-1)[0];

        // at this point, we have the final objec
        if (isPlainObject(shallowObj) && Object.prototype.hasOwnProperty.call(shallowObj, lastKey)) {
            value = shallowObj[lastKey];
        }
    }
    catch (err) {
        value = notSetValue;
    }

    return value;
};

export default safelyAccessNestedValues;
