// Dependencies
import isHotkey from 'is-hotkey';
import { createEditor, Editor } from 'slate';
import debounce from 'lodash.debounce';
import { withHistory } from 'slate-history';
import { useDropzone } from 'react-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { Slate, Editable, withReact } from 'slate-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImagePolaroid } from '@fortawesome/pro-duotone-svg-icons'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { faCode, faMicrophone, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { faBold, faItalic, faUnderline, faQuoteLeft, faList, faListOl, faH1, faH2 } from '@fortawesome/pro-solid-svg-icons'

// Api
import { fetcher } from '../../Api';

// Components
import Button from '../../Components/Button';
import AttachedImages from './components/AttachedImages';
import Spinner, { FullPageSpinner } from '../../Components/Spinner';
import { toggleMark, Toolbar, MarkButton, BlockButton, isMarkActive, isBlockActive, isLastChild, isEndOfChild } from './components';

// Styles
import css from './Write.sass';

const emptyState = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
];

const Element = (props) => {
    const { attributes, children, element } = props;

    switch (element.type) {
        case 'block-quote':
            return <blockquote {...attributes}>{children}</blockquote>
        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>
        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>
        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>
        case 'list-item':
            return <li {...attributes}>{children}</li>
        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>
        default:
            return <p {...attributes}>{children}</p>
    }
}

const Leaf = (props) => {
    let { attributes, children, leaf } = props;

    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
}

const useGetInitialState = (entryID) => {
    const [initialState, setInitialState] = useState(null);

    const getEditState = async (id) => {
        try {
            const response = await fetcher(`/api/entries/${id}`);
            
            if(response && Array.isArray(response.entry.entry)) {
                setInitialState(response.entry.entry);
            }
            else{
                setInitialState(emptyState);
            }
        }
        catch(err) {
            setInitialState(emptyState);
        }
    }

    const getDraft = async () => {
        try {
            const response = await fetcher(`/api/draft`);
            
            if(response && Array.isArray(response.draft)) {
                setInitialState(response.draft);
            }
            else{
                setInitialState(emptyState);
            }
        }
        catch(err) {
            setInitialState(emptyState);
        }
    };

    useEffect(() => {
        setInitialState(null);

        if(entryID) {
            getEditState(entryID);
        }
        else {
            getDraft();
        }
    }, [entryID]);

    return initialState;
}

const DropFiles = () => {
    const [files, setFiles] = useState([]);

    const onDrop = useCallback(async (acceptedFiles) => {
        // Do something with the files
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        console.log( acceptedFiles );

        const data = new FormData();
        acceptedFiles.forEach(file => {
            data.append('image', file);
        })

        await fetcher(`/api/upload-image`, { method: 'POST', data: data });

    }, []);
    
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
        onDrop, 
        multiple: true,
        accept: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
    });

    return (
        <div>
            <div className={css.dropFilesContainer} {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop some files here, or click to select files</p>
                }
            </div>
        </div>
    );
}

const RequestsWrapper = () => {
    const { entryID } = useParams();
    const initialState = useGetInitialState(entryID ?? null);

    if(initialState) {
        return (
            <Write
                editEntryID={entryID}
                initialState={initialState} 
            />
        );
    }

    return <FullPageSpinner />
}

const ScanPhone = () => {
    const fileRef = useRef(null);
    const { entryID } = useParams();
    const [loading, setIsLoading] = useState(false);

    const showQRCode = false;
    const getQRCodeUrl = entryID ? `${API_DOMAIN}/api/journal/entries/${entryID}/qr-code` : `${API_DOMAIN}/api/upload-qr-code`;

    const onFileChange = async ( event ) => {
        setIsLoading(true);
        const data = new FormData();
        const files = event.target.files;

        for(let ii = 0; ii < files.length; ii += 1) {
            data.append('images', files[ii]);
        }

        if(entryID) {
            data.append('entryID', entryID);
        }

        try {
            await fetcher(`/api/magic-image-upload`, { method: 'POST', contentType: undefined, formData: data });
        }
        catch(err) {
            // 
        }

        setIsLoading(false);
    }

    const handleButtonClick = () => {
        fileRef.current.click();
    }

    return (
        <div className={css.qrCodeContainer}>
            <div>
                {!showQRCode ? null : (
                    <div>
                        <div className={css.qrDescription}>Use your phone camera and scan this QR code. This will let you upload photos from your phone.</div>
                        <img className={css.qrCodeImage} src={getQRCodeUrl} />
                    </div>
                )}

                <div className={css.buttonWrapper}>
                    {!showQRCode ? null : <p>Or,</p>}
                    <Button isLoading={loading} onClick={handleButtonClick} className={css.uploadButton} color="primary" emphasis="high">Choose Photos</Button>
                    <input className={css.uploadInput} onChange={onFileChange} ref={fileRef} type="file" accept="image/*" multiple />
                </div>
            </div>
        </div>
    );
}

const UploadPhotosButton = () => {
    const fileRef = useRef(null);
    const { entryID } = useParams();
    const [loading, setIsLoading] = useState(false);

    const onFileChange = async ( event ) => {
        setIsLoading(true);
        const data = new FormData();
        const files = event.target.files;

        for(let ii = 0; ii < files.length; ii += 1) {
            data.append('images', files[ii]);
        }

        if(entryID) {
            data.append('entryID', entryID);
        }

        try {
            await fetcher(`/api/magic-image-upload`, { method: 'POST', contentType: undefined, formData: data });
        }
        catch(err) {
            // 
        }

        setIsLoading(false);
    }

    const handleButtonClick = () => {
        fileRef.current.click();
    }

    return (
        <div className={css.uploadPhotosContainer}>
            <input className={css.uploadInput} onChange={onFileChange} ref={fileRef} type="file" accept="image/*" multiple />
            <Button isLoading={loading} onClick={handleButtonClick} color="neutral" emphasis="low">
                <div className={css.uploadButtonContent}>
                    <div className={css.iconContainer}>
                        <FontAwesomeIcon icon={faImagePolaroid} />
                    </div>
                    <div className={css.uploadLabel}>Upload Photos</div>
                </div>
            </Button>
        </div>
    );
}

const ClearButton = (props) => {
    return (
        <Button onClick={props.onClick} color="neutral" emphasis="low" size="medium">
            <div>
                <FontAwesomeIcon icon={faTrash} />
            </div>
        </Button>
    );
}

const SpeechRecognition = (props) => {
    const { onSpeech } = props;

    const [isRecording, setIsRecording] = useState(false);
    const [speechAvailable, setSpeechAvailable] = useState(false);
    const recognition = useRef(null);

    const handleStart = () => {
        onSpeech('welcome to alabam.');
        recognition.current.start();
    };

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

        if(SpeechRecognition) {
            recognition.current = new SpeechRecognition();

            recognition.current.onstart = function() {
                setIsRecording(true);
            };
            
            recognition.current.onspeechend = function() {
                // when user is done speaking
                recognition.current.stop();
                setIsRecording(false);
            }
                        
            // This runs when the speech recognition service returns result
            recognition.current.onresult = function(event) {
                const transcript = event.results[0][0].transcript;
                const confidence = event.results[0][0].confidence;

                onSpeech(transcript, confidence);
            };

            setSpeechAvailable(true);
        }
    }, []);

    if(!speechAvailable) {
        return null;
    }

    return (
        <Button onClick={handleStart} color="neutral" emphasis={isRecording ? "high" : "low"} size="medium">
            <div>
                <FontAwesomeIcon icon={faMicrophone} />
            </div>
        </Button>
    );
}

const Write = (props) => {
    const { initialState, editEntryID } = props;
    const isEditingEntry = !!editEntryID;

    const history = useHistory();
    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState(initialState);
    const [isShowingQR, setIsShowingQR] = useState(false);

    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const renderElement = useCallback(props => <Element {...props} />, []);
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const toggleUploadQRCode = () => {
        setIsShowingQR(!isShowingQR);
    }

    const autoSave = async (value) => {
        if(!isEditingEntry) {
            setIsSaving(true);
            const content = JSON.stringify(value);
            await fetcher(`/api/autosave-draft`, { method: 'POST', data: { entry: content } });
            setIsSaving(false);
        }
    }

    const debouncedSave = useCallback(
        debounce(nextValue => autoSave(nextValue), 1000),
        [],
    );

    const updateValue = (newValue) => {
        setValue(newValue);
        debouncedSave(newValue);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.shiftKey === false) {
            const isCode = isMarkActive(editor, 'code');
            const isBlockQuote = isBlockActive(editor, 'block-quote');

            if(isCode || isBlockQuote) {
                event.preventDefault();
                editor.insertText('\n')
            }
        }

        if(event.key === 'ArrowDown') {
            if(isLastChild(editor)) {
                editor.insertNode({
                    type: 'paragraph',
                    children: [{ text: '' }],
                });
            }
        }

        if(event.key === 'ArrowRight') {
            if(isEndOfChild(editor)) {
                editor.insertNode({
                    type: 'paragraph',
                    children: [{ text: '' }],
                });
            }
        }

        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
            }
        }
    }

    const clearValue = async (updateDraft) => {
        editor.selection = { anchor: { path: [0,0], offset:0 }, focus: { path: [0,0], offset: 0 } };
        setValue(emptyState);

        if(updateDraft) {
            // clear the draft state
            await fetcher(`/api/autosave-draft`, { method: 'POST', data: { entry: JSON.stringify(emptyState) } });
        }
    }

    const handleSpeech = (text, confidence) => {
        if(confidence > 0.5) {
            editor.insertText(text);
        }
    };

    const handleUpdateEntry = async () => {
        const content = JSON.stringify(value);

        try {
            await fetcher(`/api/journal/entries/${editEntryID}`, { method: 'POST', data: { entry: content } });
            history.push(`/`);
        }
        catch(err) {
            // 
        }
    };

    const handleAddEntry = async () => {
        const content = JSON.stringify(value);
        const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {
            await clearValue();
            await fetcher(`/api/save-entry`, { method: 'POST', data: { entry: content, localTimezone } });
        }
        catch(err) {
            setValue(value);
        }
    }

    const handleSave = () => {
        if(isEditingEntry) {
            handleUpdateEntry();
        }
        else {
            handleAddEntry();
        }
    };

    return (
        <div className={css.main}>
            <div className={css.wrapper}>
                <Slate editor={editor} value={value} onChange={updateValue} onKeyDown={handleKeyDown}>
                    <Toolbar>
                        
                        <MarkButton format="bold" icon={faBold} />
                        <MarkButton format="italic" icon={faItalic} />
                        <MarkButton format="underline" icon={faUnderline} />

                        <div className={css.divider} />

                        <BlockButton format="heading-one" icon={faH1} />
                        <BlockButton format="heading-two" icon={faH2} />
                        
                        <div className={css.divider} />

                        <MarkButton format="code" icon={faCode} />
                        <BlockButton format="block-quote" icon={faQuoteLeft} />
                        <BlockButton format="numbered-list" icon={faListOl} />
                        <BlockButton format="bulleted-list" icon={faList} />

                        <div className={css.divider} />
                        <ClearButton onClick={clearValue} />

                        <div className={css.divider} />
                        <SpeechRecognition onSpeech={handleSpeech} />

                    </Toolbar>
                    <div className={`${css.textContainer} ${css.RichTextContainer}`}>
                        <Editable 
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            placeholder="Enter some rich text…"
                            spellCheck={true}
                            autoFocus={true}
                            placeholder=""
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </Slate>

                <div className={css.saveContainer}>
                    <div>
                        <UploadPhotosButton />
                    </div>
                    <div className={css.autosave}>
                        {!isEditingEntry ? (
                            <div className={css.innerwrapper}>{isSaving ? <Spinner size="tiny" /> : "autosaved"}</div>
                        ) : null}
                    </div>
                    <Button onClick={handleSave} color="positive" emphasis="high">{isEditingEntry ? 'Update' : 'Post'}</Button>
                </div>

                <div>
                    {isShowingQR ? <ScanPhone /> : null}
                </div>

                <div className={css.attachedImages}>
                    <AttachedImages />
                </div>

                {/* <div className={css.dropFiles}>
                    <DropFiles />
                </div> */}
            </div>
        </div>
    );
}

export default RequestsWrapper;