// Dependencies
import copy from 'copy-to-clipboard';
import React, { useEffect } from "react";

// Styles
import css from './Branding.sass';

const Colors = (props: { colors: string[], shades:number[] }) => {
    const { colors, shades } = props;

    const gridTemplateColumns = shades.map(() => '1fr').join(' ');

    const handleCopy = (str: string) => {
        console.log( 'str ', str );
        copy(str);
    }

    return (
        <div className={css.colorsContainer}>
            <div className={css.colorsContent}>

                <div className={css.highlighterContainer} style={{ gridTemplateColumns }}>
                    {shades.map((dd, ii) => {

                        if(ii === Math.floor(shades.length / 2)) {
                            return <div className={css.highlighter} />;
                        }

                        return <div />
                    })}
                </div>

                {colors.map(color => {
                    return (
                        <div key={color} className={css.colorWrapper}>
                            <div>{color}</div>
                            <div className={css.shadesWrapper} style={{ gridTemplateColumns }}>
                                {shades.map(shade => {
                                    return (
                                        <div key={shade} onClick={() => handleCopy(`theme.getColor(${color}, ${shade})`)} className={`${css.shadeBox} ${css[`${color}-shade-${shade}`]}`} />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}

            </div>
        </div>
    );
}

const Branding = () => {
    const colors = [
        'blue',
        'red',
        'turquoise',
        'pink',
        'yellow',
        'green',
        'neutral',
    ];

    const shades = Array.from({length: 11}, (_, i) => i + 1)

    return (
        <div>
            <div className={css.container}>
                <div>Branding</div>
                <Colors colors={colors} shades={shades} />
            </div>
        </div>
    );
}

export default Branding;