// Dependencies
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from 'swiper/core';
import React, { useEffect, useState, useRef } from 'react';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Components
import { FullPageSpinner } from '../../Components/Spinner';

// Styles
import css from './Home.sass';
import './pagination.css';
import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";

declare let API_DOMAIN: string;

// install Swiper modules
SwiperCore.use([Pagination]);

const SwiperLightBox = (props: { imageID: string, imageGallery: string[], onClose: () => void }) => {
    const { imageID, imageGallery, onClose } = props;

    const swiperInstance = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    const onInit = (instance: any) => {
        swiperInstance.current = instance;

        const initialIndex = (imageGallery.indexOf(imageID)) + 1; // they are 1-based??
        swiperInstance.current.slideTo(initialIndex, 0);
        setIsLoaded(true);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        const { key } = event;

        if(key === 'ArrowRight') {
            swiperInstance.current.slideNext();
        }
        else if(key === 'ArrowLeft') {
            swiperInstance.current.slidePrev();
        }
        else if(key === 'Escape') {
            onClose();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = null;
        }
    }, []);

    return (
        <div className={css.lightbox}>

            {/* <div className={css.bg} /> */}

            <div className={css.toolbar}>                
                <div className={css.toolbarContent}>

                    <div />
                    <div onClick={onClose} className={css.closeButton}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>

                </div>
            </div>

            <Swiper onSwiper={onInit} pagination={{ "dynamicBullets": true }} loop={true} className={css.swiperContainer}>
                {imageGallery.map((imageID: string) => {
                    const src = `${API_DOMAIN}/images/${imageID}`;

                    return (
                        <SwiperSlide key={imageID}>
                            <div className={css.imageContainer}>
                                <img className={css.swiperImage} src={src} />
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            {!isLoaded ? <FullPageSpinner /> : null}
        </div>
    );
}

const ImageLightBoxCustom = (props: { imageID: string, imageGallery: string[], onClose: () => void }) => {
    const { imageID, imageGallery, onClose } = props;

    const imageContainer = useRef(null);
    const [activeImageID, setActiveImageID] = useState(imageID);

    const src = `${API_DOMAIN}/images/${activeImageID}`;

    const goToPreviousImage = () => {
        const currentIndex = imageGallery.indexOf(activeImageID);
        let nextIndex = currentIndex - 1;

        if(nextIndex < 0) {
            nextIndex = imageGallery.length - 1;
        }

        setActiveImageID(imageGallery[nextIndex]);
    }

    const goToNextImage = () => {
        const currentIndex = imageGallery.indexOf(activeImageID);
        const nextIndex = (currentIndex + 1) % imageGallery.length;

        setActiveImageID(imageGallery[nextIndex]);
    }

    const handleKeyDown = (event: KeyboardEvent) => {
        const { key } = event;

        if(key === 'ArrowRight') {
            goToNextImage();
        }
        else if(key === 'ArrowLeft') {
            goToPreviousImage();
        }
        else if(key === 'Escape') {
            onClose();
        }
    }
    
    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return (
        <div className={css.lightbox}>
            <div className={css.bg} />

            <div className={css.toolbar}>                
                <div className={css.toolbarContent}>

                    <div />
                    <div onClick={onClose} className={css.closeButton}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>

                </div>
            </div>

            <div ref={imageContainer} className={css.imageContainer}>
                <img src={src} />
            </div>

            <div className={css.gallery}>
                {imageGallery.map((id: string) => {
                    const isActive = id === activeImageID;

                    return (
                        <div key={id} className={`${css.image} ${isActive ? css.isActive : ''}`}>
                            <div className={css.imageDot} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default SwiperLightBox;