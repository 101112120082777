// Dependencies
import { useSlate } from 'slate-react';
import React, { useCallback, useMemo, useState } from 'react';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Components
import Button from '../../Components/Button';

// Styles
import css from './Write.sass';

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const isLastChild = (editor) => {
    const childrenCount = editor.children.length;
    const currentChildIndex = editor.selection.focus.path[0] + 1;

    return childrenCount === currentChildIndex;
}

export const isEndOfChild = (editor) => {
    try {
        const focusPosition = editor.selection.focus.offset;
        const anchorPosition = editor.selection.anchor.offset;

        // if there is highlighted text, return false
        if(focusPosition !== anchorPosition) {
            return false;
        }

        const currentChildIndex = editor.selection.focus.path[0];
        const currentSubChildIndex = editor.selection.focus.path[1];

        const currentChild = editor.children[currentChildIndex];
        const currentSubChild = currentChild.children[currentSubChildIndex];

        const textLength = currentSubChild.text.length;
        return focusPosition === textLength;
    }
    catch(err) {
        // pass
    }

    return false;
}

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false
}

export const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match
}

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            LIST_TYPES.includes(
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
            ),
        split: true,
    })
    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const Toolbar = (props) => {
    return (
        <div className={css.toolbar}>
            {props.children}
        </div>
    );
}

export const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);

    return (
        <Button
            size="medium"
            color="neutral"
            emphasis={isActive ? "high" : "low"}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <div>
                <FontAwesomeIcon icon={icon} />
            </div>
        </Button>
    )
}

export const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    const isActive = isBlockActive(editor, format);
    
    return (
        <Button
            size="medium"
            color="neutral"
            emphasis={isActive ? "high" : "low"}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <div>
                <FontAwesomeIcon icon={icon} />
            </div>
        </Button>
    )
}
