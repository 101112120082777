// Dependencies
import useSWR  from 'swr';
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons';
import { DragDropContext, Droppable, Draggable, DroppableProvided, DroppableStateSnapshot, DraggableProvided, DraggableStateSnapshot, DropResult } from "react-beautiful-dnd";

// Api
import { fetcher } from '../../../Api';

// Components
import Button from '../../../Components/Button';
import Spinner from '../../../Components/Spinner';

// Styles
import css from '../Write.sass';


declare let API_DOMAIN: string;

const useFetchImages = (entryID: string) => {
    const [images, setImages] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);

    const fetchUrl = entryID ? `/api/journal/entries/${entryID}/images` : '/api/journal/attached-images';
    const { data, mutate } = useSWR(fetchUrl, { refreshInterval: 2500 });
    const responseImages = data && data.images ? data.images : [];

    const responseImageIDs = responseImages.map((dd: { _id: string }) => dd._id).join('');

    useEffect(() => {
        setImages(responseImages);
        setIsLoading(false);
    }, [responseImageIDs]);

    const setLoadingState = () => {
        setIsLoading(true);
    }

    const refreshData = () => {
        mutate();
        setIsLoading(false);
    }

    return {
        images,
        isLoading,
        refreshData,
        setLoadingState,
    };
};

const getListStyle = (isDraggingOver: boolean) => ({
    // background: isDraggingOver ? "lightblue" : "lightgrey",
    // width: 250
    // display: 'flex',
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
  
    // change background colour if dragging
    // background: isDragging ? "lightgreen" : "grey",
  
    // styles we need to apply on draggables
    ...draggableStyle
});

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const AttachedImages = () => {
    const { entryID } = useParams<{ entryID: string }>();
    const [ isDeleteMode, setIsDeleteMode ] = useState(false);
    const { images, isLoading, setLoadingState, refreshData } = useFetchImages(entryID);

    const handleDelete = async (imageID: string) => {
        await fetcher(`/api/journal/attached-images`, { method: 'DELETE', data: { imageID } });
    };

    const onDragEnd = async (result: DropResult) => {
        if (!result.destination) {
            return;
        }
      
        if (result.destination.index === result.source.index) {
            return;
        }

        const newImageOrder = reorder(
            images.map(dd => dd._id),
            result.source.index,
            result.destination.index,
        );

        setLoadingState();
        await fetcher(`/api/journal/entries/${entryID}/images`, { method: 'POST', data: { imageOrder: newImageOrder } });
        refreshData();
    };

    return (
        <div className={css.attachments}>
            <div className={css.scrollContainer}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable isCombineEnabled={true} droppableId="droppable" direction="horizontal">
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                            <div
                                className={css.imagesContainer}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {images.map((image, ii) => {
                                    return (
                                        <Draggable isDragDisabled={isDeleteMode} key={image._id} draggableId={image._id} index={ii}>
                                            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                >
                                                    <div key={image._id} className={css.attachmentWrapper}>
                                                        <div className={css.attachmentInsideWrapper}>
                                                            <img className={css.attachment} src={`${API_DOMAIN}/images/${image._id}`} />
                                                            {isDeleteMode ? (
                                                                <div onClick={() => handleDelete(image._id)} className={css.hoverCover}>
                                                                    <div className={css.shade} />
                                                                    <FontAwesomeIcon icon={faTimesCircle} />
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </div>

            {isLoading ? (
                <div className={css.isLoadingContainer}>
                    <Spinner />
                </div>
            ) : null}
            
            {images.length > 0 ? (
                <Button onClick={() => setIsDeleteMode(!isDeleteMode)} color="destructive" emphasis="medium">{isDeleteMode ? 'Turn On Image Reordering' : 'Turn On Image Deleting'}</Button>
            ) : null}
        </div>
    );
}

export default AttachedImages;
