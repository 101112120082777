// Dependencies
import React, { useRef } from "react";

// Styles
import css from './Input.sass'; 

interface InputProps {
    value: string,
    className?: string,
    icon? : React.ReactNode,
    rightSection? : React.ReactNode,
    rightSectionWidth?: number,
    onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void,
}

const Input = React.forwardRef((props: InputProps, ref: { current: HTMLInputElement | null }) => {
    const { value, onChange, className = '', icon, rightSection, rightSectionWidth } = props;

    const inputClasses = [css.input, className, (icon ? css.hasIcon : ''), (rightSection ? css.hasRightSection : '')];

    const handleFocus = () => {
        if(ref && ref.current) {
            ref.current.focus();
        }
    }

    return (
        <div className={css.main}>
            {icon ? (
                <div className={css.icon}>
                    {icon}
                </div>
            ) : null}

            <input ref={ref as any} className={inputClasses.join(' ')} type="text" value={value} onChange={event => onChange(event.target.value, event)} />

            {rightSection ? (
                <div onClick={handleFocus} className={css.rightSection} style={{ width: rightSectionWidth ? rightSectionWidth : 'auto'}}>
                    {rightSection}
                </div>
            ) : null}
        </div>
    );
});

export default Input;