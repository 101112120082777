// Dependencies
import useSWR  from 'swr';
import isHotkey from 'is-hotkey';
import { createEditor, Editor, Node, Transforms, Element as SlateElement } from 'slate';
import debounce from 'lodash.debounce';
import { withHistory } from 'slate-history';
import { useDropzone } from 'react-dropzone';
import { useHistory, useParams } from 'react-router-dom';
import { Slate, Editable, withReact } from 'slate-react';
import { faImagePolaroid } from '@fortawesome/pro-duotone-svg-icons'
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { faCode, faTimesCircle, faMicrophone, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { faBold, faItalic, faUnderline, faQuoteLeft, faList, faListOl, faH1, faH2 } from '@fortawesome/pro-solid-svg-icons'

// Api
import { fetcher } from '../../Api';

// Project
import Button from '../../Components/Button';
import Spinner from '../../Components/Spinner';
import { insertImage, Element, Leaf, toggleMark, Toolbar, MarkButton, BlockButton, isMarkActive, isBlockActive, isLastChild, isEndOfChild, InsertImageButton, SpeechRecognition } from './components';

// Styles
import css from './Editor.sass';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

const isImageUrl = () => {
    return true;
}

const withImages = editor => {
    const { insertData, isVoid } = editor
  
    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }
  
    editor.insertData = data => {
        const text = data.getData('text/plain')
        const { files } = data
  
        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader()
                const [mime] = file.type.split('/')
  
                if (mime === 'image') {
                    reader.addEventListener('load', () => {
                        const url = reader.result
                        insertImage(editor, url)
                    })
  
                    reader.readAsDataURL(file)
                }
            }
        } else if (isImageUrl(text)) {
            insertImage(editor, text)
        } else {
            insertData(data)
        }
    }
  
    return editor
}

const withLayout = (editor, type) => {
    const { normalizeNode } = editor
  
    editor.normalizeNode = ([node, path]) => {
        if (path.length === 0) {
            if (editor.children.length < 1) {
                const title = {
                    type: 'title',
                    children: [{ text: 'Untitled' }],
                }
                Transforms.insertNodes(editor, title, { at: path.concat(0) })
            }

            // the directory type allows a title and one paragraph
            if (type === 'directory' && editor.children.length > 2) {
                Transforms.mergeNodes(editor);
            }
  
            for (const [child, childPath] of Node.children(editor, path)) {
                // if it is our first element, type should be title
                let type = childPath[0] === 0 ? 'title' : child.type;

                // prevents the title from extending to the next line
                if(childPath[0] > 0 && type === 'title') {
                    type = 'paragraph';
                }
  
                if (SlateElement.isElement(child) && child.type !== type) {
                    const newProperties = { type }
                    Transforms.setNodes(editor, newProperties, { at: childPath })
                }
            }
        }
  
        return normalizeNode([node, path])
    }
  
    return editor
}

const PrimaryEditor = (props) => {
    const {
        type,
        onSave,
        emptyState,
        initialState,
        autoSaveEndpoint,
    } = props;

    const [isSaving, setIsSaving] = useState(false);
    const [value, setValue] = useState(initialState);

    const renderLeaf = useCallback(props => <Leaf {...props} />, []);
    const renderElement = useCallback(props => <Element {...props} />, []);
    const editor = useMemo(() => withLayout(withImages(withHistory(withReact(createEditor()))), type), []);

    const autoSave = async (value) => {
        if(autoSaveEndpoint) {
            setIsSaving(true);
            const content = JSON.stringify(value);
            await fetcher(autoSaveEndpoint, { method: 'POST', data: { entry: content } });
            setIsSaving(false);
        }
    }

    const debouncedSave = useCallback(
        debounce(nextValue => autoSave(nextValue), 1000),
        [],
    );

    const updateValue = (newValue) => {
        setValue(newValue);
        debouncedSave(newValue);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.shiftKey === false) {
            const isCode = isMarkActive(editor, 'code');
            const isBlockQuote = isBlockActive(editor, 'block-quote');

            if(isCode || isBlockQuote) {
                event.preventDefault();
                editor.insertText('\n')
            }
        }

        if(event.key === 'ArrowDown') {
            if(isLastChild(editor)) {
                editor.insertNode({
                    type: 'paragraph',
                    children: [{ text: '' }],
                });
            }
        }

        if(event.key === 'ArrowRight') {
            if(isEndOfChild(editor)) {
                editor.insertNode({
                    type: 'paragraph',
                    children: [{ text: '' }],
                });
            }
        }

        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
            }
        }
    }

    const handleSpeech = (text, confidence) => {
        if(confidence > 0.5) {
            editor.insertText(text);
        }
    };

    return (
        <div className={css.main}>
            <div className={css.wrapper}>
                <Slate editor={editor} value={value} onChange={updateValue} onKeyDown={handleKeyDown}>
                    <Toolbar>
                        
                        <MarkButton format="bold" icon={faBold} />
                        <MarkButton format="italic" icon={faItalic} />
                        <MarkButton format="underline" icon={faUnderline} />

                        <div className={css.divider} />

                        <BlockButton format="heading-one" icon={faH1} />
                        <BlockButton format="heading-two" icon={faH2} />
                        
                        <div className={css.divider} />

                        <MarkButton format="code" icon={faCode} />
                        <BlockButton format="block-quote" icon={faQuoteLeft} />
                        <BlockButton format="numbered-list" icon={faListOl} />
                        <BlockButton format="bulleted-list" icon={faList} />

                        <div className={css.divider} />
                        <InsertImageButton editor={editor} />

                        <div className={css.divider} />
                        <SpeechRecognition onSpeech={handleSpeech} />

                    </Toolbar>
                    <div className={`${css.textContainer} ${css.RichTextContainer}`}>
                        <Editable
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            placeholder="Enter some rich text…"
                            spellCheck={true}
                            autoFocus={true}
                            placeholder=""
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                </Slate>

                <div className={css.saveContainer}>
                    <div className={css.autosave}>
                        {autoSaveEndpoint ? (
                            <div className={css.innerwrapper}>{isSaving ? <Spinner size="tiny" /> : "autosaved"}</div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrimaryEditor;
