// Dependencies
import useSWR  from 'swr';
import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile } from '@fortawesome/pro-duotone-svg-icons';

// Api
import { fetcher } from '../../Api';

// Components
import Editor from '../../Components/Editor';

// Styles
import css from './WorkspaceDocument.sass';
import { FullPageSpinner } from '../../Components/Spinner';

const emptyState = [
    {
        type: 'title',
        children: [{ text: 'Untitled' }],
    },
];

const useWorkspaceDocument = (documentID: string): { document: any, isLoading: boolean, hasFetched: boolean, type: string } => {
    const [state, setState] = useState({ hasFetched: false, isLoading: true, document: null, type: null });

    const getDocument = async () => {
        try {
            setState({ ...state, isLoading: true });
            const response = await fetcher(`/api/workspaces/${documentID}`);
            setState({ isLoading: false, hasFetched: true, document: response.document.entry, type: response.document.type });
        }
        catch(err) {
            console.log( err );
            setState({ ...state, isLoading: false, hasFetched: true });
        }
    };

    useEffect(() => {
        getDocument();
    }, [documentID]);

    return {
        type: state.type,
        document: state.document,
        isLoading: state.isLoading,
        hasFetched: state.hasFetched,
    };
}

const ItemIcon: any = (props: { type: string }) => {
    const { type } = props;

    if(type === 'directory') {
        return <FontAwesomeIcon icon={faFolder} />
    }

    if(type === 'file') {
        return <FontAwesomeIcon icon={faFile} />
    }

    return null;
};

const DirectoryFiles = (props: { parentDocumentID: string }) => {
    const { parentDocumentID } = props;
    const { data, revalidate } = useSWR(`/api/workspaces/personal/structure/?parentDocumentID=${parentDocumentID}`);

    if(!data) {
        return null;
    }

    return (
        <div>
            <div>
                {data.structure.map((item: any) => {
                    return (
                        <Link key={item._id} className={css.workspaceItem} to={`/workspaces/personal/${item._id}/`}>
                            <div className={css.content}>
                                <div className={css.iconWrapper}>
                                    <ItemIcon type={item.type} />
                                </div>
                                {item.title === '' ? (
                                    <div className={css.untitled}>Untitled item...</div>
                                ) : <div>{item.title}</div>}
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

const WorkspaceDocument: React.FC = () => {
    const { documentID } = useParams<{ workspaceID: string, documentID: string }>();
    const { document, type, isLoading } = useWorkspaceDocument(documentID);

    if(isLoading) {
        return <FullPageSpinner />;
    }

    return (
        <div className={css.main}>
            <div className={css.container}>
                <Editor
                    type={type}
                    emptyState={emptyState}
                    initialState={document}
                    autoSaveEndpoint={`/api/workspaces/${documentID}/`}
                />
            </div>

            {type === 'directory' ? (
                <div className={css.directoryContainer}>
                    <DirectoryFiles parentDocumentID={documentID} />
                </div>
            ) : null}
        </div>
    );
}

export default WorkspaceDocument;