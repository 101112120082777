// Dependencies
import create from 'zustand';

type AuthState = {
    user: null | {
        id: string,
        email: string,
    },
    token: string,
    isAuthenticated: boolean,
    setAuthentication: (userID: string, email: string, token: string) => void
    logout: () => void,
}

const useAuthStore = create<AuthState>(set => ({
    user:  null,
    token: null,
    isAuthenticated: false,

    setAuthentication: (userID:string, email: string, token: string) => {
        set({
            isAuthenticated: true,
            user: {
                id: userID,
                email: email,
            },
            token: token,
        });
    },

    logout: () => {
        set({ user: null, token: null, isAuthenticated: false })
    }
}))

export default useAuthStore;