// Dependencies
import React from "react";
import ReactDOM from "react-dom";

// Project
import App from './App/Main';

ReactDOM.render(
    <App />, 
    document.getElementById("root"),
);
