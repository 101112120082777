// Dependencies
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Components
import Navigation from './Navigation';

// Pages
import Home from "../Views/Home";
import Write from "../Views/Write/index";
import Branding from "../Views/Branding";
import Workspace from "../Views/Workspace";
import WorkspaceDocument from "../Views/WorkspaceDocument";

// styles
import css from './Main.sass';
import 'react-nestable/dist/styles/index.css';

const AuthenticatedApp = () => {
    return (
        <Router>

            <Navigation />
            
            <Switch>
                <Route path="/branding">
                    <div className={css.mainBody}>
                        <Branding />
                    </div>
                </Route>

                <Route path="/write">
                    <div className={css.mainBody}>
                        <Write />
                    </div>
                </Route>

                <Route path="/edit/:entryID">
                    <div className={css.mainBody}>
                        <Write />
                    </div>
                </Route>

                <Route path="/workspaces/:workspaceID/:documentID">
                    <div className={css.mainBody}>
                        <WorkspaceDocument />
                    </div>
                </Route>

                <Route path="/workspaces/:workspaceID">
                    <div className={css.mainBody}>
                        <Workspace />
                    </div>
                </Route>

                <Route path="/">
                    <div className={css.mainBody}>
                        <Home />
                    </div>
                </Route>
            </Switch>
        </Router>
    );
}

export default AuthenticatedApp;
