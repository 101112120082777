// Dependencies
import React  from 'react';

// Styles
import css from './Spinner.sass';

type Size = 'standard' | 'medium' | 'small' | 'tiny'

interface BaseProps {
    size? : Size,
}

const Spinner = (props: BaseProps) => {
    const { size = 'standard' } = props;

    const styleClasses = `${css.main} ${css[`size-${size}`]}`;

    return (
        <div className={styleClasses}>
            <div className={css.innerCircle} />
            <div className={css.innerCircleSolid} />
        </div>
    );
}

export const FullPageSpinner = () => {
    return (
        <div className={css.fullPage}>
            <Spinner />
        </div>
    );
}

export default Spinner;