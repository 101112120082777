declare let API_DOMAIN: string;

interface FetcherOptions {
    data?: any,
    formData?: any,
    method?: string,
    contentType?: string,
}

export const fetcher = async (resource: string, options: FetcherOptions = {}) => {
    const endpoint = `${API_DOMAIN}${resource}`;

    const method = options.method ?? 'GET';
    let contentType = options.contentType ?? 'application/json';
    
    let body = undefined;
    const headers: any = {};

    if(options.data) {
        body = JSON.stringify(options.data);
    }
    else if(options.formData) {
        body = options.formData;
        contentType = null;
    }

    if(contentType) {
        headers['Content-Type'] = contentType;
    }

    try {
        const response = await fetch(endpoint, {
            method,
            credentials: 'include',
            body: body,
            headers: headers,
        });

        return response.json();
    }
    catch(err) {
        console.log( err );
    }
}
