// Dependencies
import React, { useState } from "react";
import { useNotifications } from '@mantine/notifications';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from '@fortawesome/pro-duotone-svg-icons';

// Api
import { fetcher } from "../Api";

// Components
import Input from '../Components/Input';
import Button from '../Components/Button';
import Spinner from '../Components/Spinner';

// Styles
import css from './Login.sass';

const Login = () => {
    const [email, setEmail] = useState('');
    const notifications = useNotifications();
    const [isLoading, setIsLoading] = useState(false);

    const getMagicLogin = async () => {
        setIsLoading(true);
        await fetcher('/magic-login', { method: 'POST', data: { email } });
        setIsLoading(false);
        setEmail('');

        notifications.showNotification({
            title: 'Check you email',
            message: '👋 We sent you an email with a magic login link.',
        });
    }

    return (
        <div className={css.main}>
            <div>
                <div>
                    <div className={css.iconCircle}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </div>
                </div>
                <div className={css.instruction}>Type in Email address</div>
                <div className={css.inputContainer}>
                    <Input className={css.emailInput} value={email} onChange={setEmail} />
                </div>
                <div className={css.buttonContainer}>
                    {isLoading ? (
                        <Spinner size="small" />
                    ) : (
                        <Button size="medium" color="primary" emphasis="high" onClick={getMagicLogin}>Get Magic Login Link</Button>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Login;