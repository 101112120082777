// Dependencies
import React, { useRef, useEffect, useState } from 'react';
import { useSlate, useSelected, useFocused } from 'slate-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor, Element as SlateElement, Transforms } from 'slate';
import { faMicrophone, faTrash, faImages } from '@fortawesome/pro-duotone-svg-icons';

// Api
import { fetcher } from '../../Api';

// Components
import Button from '../../Components/Button';

// Styles
import css from './Editor.sass';

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const insertImage = (editor, imageID) => {
    const text = { text: '' };
    const image = { type: 'image', imageID, children: [text] };
    Transforms.insertNodes(editor, image);
}

const ImageElement = ({ attributes, children, element }) => {
    const focused = useFocused();
    const selected = useSelected();

    const imageUrl = element.url ? element.url : `${API_DOMAIN}/workspace-images/${element.imageID}`;

    return (
        <div {...attributes}>
            <div contentEditable={false}>
                <img
                    src={imageUrl}
                    className={`${css.imageElement} ${selected && focused ? css.hasFocus : ''}`}
                />
            </div>
            {children}
        </div>
    );
}

export const Element = (props) => {
    const { attributes, children, element } = props;

    switch (element.type) {
        case 'block-quote': {
            return <blockquote {...attributes}>{children}</blockquote>
        }
        case 'bulleted-list': {
            return <ul {...attributes}>{children}</ul>
        }
        case 'title': {
            const hasContent = element.children[0].text !== '';
            return <h1 className={hasContent ? '' : css.renderPlaceholder} {...attributes}>{children}</h1>
        }
        case 'heading-one':{
            return <h2 {...attributes}>{children}</h2>
        }
        case 'heading-two':{
            return <h3 {...attributes}>{children}</h3>
        }
        case 'list-item':{
            return <li {...attributes}>{children}</li>
        }
        case 'numbered-list': {
            return <ol {...attributes}>{children}</ol>
        }
        case 'image': {
            return <ImageElement {...props} />
        }
        default: {
            return <p {...attributes}>{children}</p>
        }
    }
}

export const Leaf = (props) => {
    let { attributes, children, leaf } = props;

    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}

export const isLastChild = (editor) => {
    const childrenCount = editor.children.length;
    const currentChildIndex = editor.selection.focus.path[0] + 1;

    return childrenCount === currentChildIndex;
}

export const isEndOfChild = (editor) => {
    try {
        const focusPosition = editor.selection.focus.offset;
        const anchorPosition = editor.selection.anchor.offset;

        // if there is highlighted text, return false
        if(focusPosition !== anchorPosition) {
            return false;
        }

        const currentChildIndex = editor.selection.focus.path[0];
        const currentSubChildIndex = editor.selection.focus.path[1];

        const currentChild = editor.children[currentChildIndex];
        const currentSubChild = currentChild.children[currentSubChildIndex];

        const textLength = currentSubChild.text.length;
        return focusPosition === textLength;
    }
    catch(err) {
        // pass
    }

    return false;
}

export const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false
}

export const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match
}

export const toggleMark = (editor, format) => {
    const isActive = isMarkActive(editor, format)

    if (isActive) {
        Editor.removeMark(editor, format)
    } else {
        Editor.addMark(editor, format, true)
    }
}

const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format)
    const isList = LIST_TYPES.includes(format)

    Transforms.unwrapNodes(editor, {
        match: n =>
            LIST_TYPES.includes(
                !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
            ),
        split: true,
    })
    const newProperties = {
        type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    }
    Transforms.setNodes(editor, newProperties)

    if (!isActive && isList) {
        const block = { type: format, children: [] }
        Transforms.wrapNodes(editor, block)
    }
}

export const Toolbar = (props) => {
    return (
        <div className={css.toolbar}>
            {props.children}
        </div>
    );
}

export const MarkButton = ({ format, icon }) => {
    const editor = useSlate();
    const isActive = isMarkActive(editor, format);

    return (
        <Button
            size="medium"
            color="neutral"
            emphasis={isActive ? "high" : "low"}
            onMouseDown={event => {
                event.preventDefault()
                toggleMark(editor, format)
            }}
        >
            <div>
                <FontAwesomeIcon icon={icon} />
            </div>
        </Button>
    )
}

export const BlockButton = ({ format, icon }) => {
    const editor = useSlate();
    const isActive = isBlockActive(editor, format);
    
    return (
        <Button
            size="medium"
            color="neutral"
            emphasis={isActive ? "high" : "low"}
            onMouseDown={event => {
                event.preventDefault()
                toggleBlock(editor, format)
            }}
        >
            <div>
                <FontAwesomeIcon icon={icon} />
            </div>
        </Button>
    )
}

export const ClearButton = (props) => {
    return (
        <Button onClick={props.onClick} color="neutral" emphasis="low" size="medium">
            <div>
                <FontAwesomeIcon icon={faTrash} />
            </div>
        </Button>
    );
}

export const InsertImageButton = (props) => {
    const fileRef = useRef(null);
    const [loading, setIsLoading] = useState(false);

    const handleButtonClick = () => {
        fileRef.current.click();
    }

    const onFileChange = async ( event ) => {
        setIsLoading(true);
        const data = new FormData();
        const files = event.target.files;
        
        for(let ii = 0; ii < files.length; ii += 1) {
            data.append('image', files[ii]);
        }
        
        try {
            const response = await fetcher(`/api/workspaces/images`, { method: 'POST', contentType: undefined, formData: data });
            insertImage(props.editor, response.imageID)
        }
        catch(err) {
            // 
        }
        
        setIsLoading(false);
    }

    return (
        <React.Fragment>
            <Button isLoading={loading} onClick={handleButtonClick} color="neutral" emphasis="low" size="medium">
                <div>
                    <FontAwesomeIcon icon={faImages} />
                </div>
            </Button>
            <input className={css.uploadImageInput} onChange={onFileChange} ref={fileRef} type="file" accept="image/*" />
        </React.Fragment>
    );
}

export const SpeechRecognition = (props) => {
    const { onSpeech } = props;

    const [isRecording, setIsRecording] = useState(false);
    const [speechAvailable, setSpeechAvailable] = useState(false);
    const recognition = useRef(null);

    const handleStart = () => {
        onSpeech('welcome to alabam.');
        recognition.current.start();
    };

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

        if(SpeechRecognition) {
            recognition.current = new SpeechRecognition();

            recognition.current.onstart = function() {
                setIsRecording(true);
            };
            
            recognition.current.onspeechend = function() {
                // when user is done speaking
                recognition.current.stop();
                setIsRecording(false);
            }
                        
            // This runs when the speech recognition service returns result
            recognition.current.onresult = function(event) {
                const transcript = event.results[0][0].transcript;
                const confidence = event.results[0][0].confidence;

                onSpeech(transcript, confidence);
            };

            setSpeechAvailable(true);
        }
    }, []);

    if(!speechAvailable) {
        return null;
    }

    return (
        <Button onClick={handleStart} color="neutral" emphasis={isRecording ? "high" : "low"} size="medium">
            <div>
                <FontAwesomeIcon icon={faMicrophone} />
            </div>
        </Button>
    );
}

// export const UploadPhotosButton = () => {
//     const fileRef = useRef(null);
//     const { entryID } = useParams();
//     const [loading, setIsLoading] = useState(false);

//     const onFileChange = async ( event ) => {
//         setIsLoading(true);
//         const data = new FormData();
//         const files = event.target.files;

//         for(let ii = 0; ii < files.length; ii += 1) {
//             data.append('images', files[ii]);
//         }

//         if(entryID) {
//             data.append('entryID', entryID);
//         }

//         try {
//             await fetcher(`/api/magic-image-upload`, { method: 'POST', contentType: undefined, formData: data });
//         }
//         catch(err) {
//             // 
//         }

//         setIsLoading(false);
//     }

//     const handleButtonClick = () => {
//         fileRef.current.click();
//     }

//     return (
//         <div className={css.uploadPhotosContainer}>
//             <input className={css.uploadInput} onChange={onFileChange} ref={fileRef} type="file" accept="image/*" multiple />
//             <Button isLoading={loading} onClick={handleButtonClick} color="neutral" emphasis="low">
//                 <div className={css.uploadButtonContent}>
//                     <div className={css.iconContainer}>
//                         <FontAwesomeIcon icon={faImagePolaroid} />
//                     </div>
//                     <div className={css.uploadLabel}>Upload Photos</div>
//                 </div>
//             </Button>
//         </div>
//     );
// }