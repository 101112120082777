// Dependencies
import React, { PropsWithChildren } from 'react';

// Styles
import css from './Button.sass';

type Size = 'standard' | 'medium'
type Emphasis = 'low' | 'slight' | 'medium' | 'high'
type Color = 'primary' | 'transparent' | 'positive' | 'destructive' | 'neutral'

interface BaseProps {
    size? : Size,
    color : Color,
    emphasis: Emphasis,
    className?: string,
    onClick?: () => void,
    onMouseDown?: () => void,
    isLoading?: boolean,
}

const Spinner = () => {
    return (
        <div className={css.spinnerContainer}>
            <div className={css.spinner}>
                <div />
            </div>
        </div>
    );
}

const Button = (props: PropsWithChildren<BaseProps>) => {
    const { children, size = 'standard', emphasis, color, className, onClick, onMouseDown, isLoading = false } = props;

    const styleClasses = `${css[`size-${size}`]} ${css[`emphasis-${emphasis}`]} ${css[`color-${color}`]}`;

    return (
        <button onMouseDown={onMouseDown} onClick={onClick} className={`${css.main} ${styleClasses} ${className} ${isLoading ? css.isLoading : ''}`}>
            <span>{children}</span>
            {isLoading ? <Spinner /> : null}
        </button>
    );
}

export default Button;