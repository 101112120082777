// Dependencies
import useSWR  from 'swr';
import Nestable from 'react-nestable';
import React, { useEffect } from "react";
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFile, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

// Api
import { fetcher } from '../../Api';

// Components
import Button from '../../Components/Button';

// Utils
import get from '../../utils/get'

// Styles
import css from './Workspace.sass';
import 'react-nestable/dist/styles/index.css';

interface Document {
    _id: string,
    title: string,
    type: string,
    structure?: Document[],
}

interface FormattedDocument {
    id: string,
    type: string,
    title: string,
    children?: FormattedDocument[],
}

const ItemIcon: any = (props: { type: string }) => {
    const { type } = props;

    if(type === 'directory') {
        return <FontAwesomeIcon icon={faFolder} />
    }

    if(type === 'file') {
        return <FontAwesomeIcon icon={faFile} />
    }

    return null;
};

const formatStructure = (structure: Document[]): FormattedDocument[] => {
    const formatted = structure.map((item) => {

        const obj = {
            id: item._id,
            type: item.type,
            title: item.title,
            children: item.structure ? formatStructure(item.structure) : [],
        };

        return obj;
    });

    return formatted;
}

const useFetchWorkspaceStructure = () => {
    const { data, revalidate } = useSWR(`/api/workspaces`);

    const formattedStructure = data?.workspace?.structure ?? [];

    return { data, structure: formatStructure(formattedStructure), revalidate };
}

const renderWorkspaceItem = ({ item }: { item: any }) => {
    const handleDelete = () => {
        // 
    };

    const collect = (props: any) => {
        return { name: props.name };
    };

    const attributes = {
        'data-id': item.id,
        className: 'example-multiple-targets well'
    };

    return (
        <ContextMenuTrigger id="context" attributes={attributes} collect={collect}>
            <div key={item.id}>
                <Link to={`/workspaces/personal/${item.id}/`} className={css.document}>
                    <div className={css.iconWrapper}>
                        <ItemIcon type={item.type} />
                    </div>
                    {item.title === '' ? (
                        <div className={css.untitled}>Untitled item...</div>
                    ) : <div>{item.title}</div>}
                </Link>
            </div>
        </ContextMenuTrigger>
    );
}

const Workspace: React.FC = () => {
    const history = useHistory();
    const { structure, revalidate } = useFetchWorkspaceStructure();

    const createDocument = async () => {
        const response = await fetcher('/api/workspaces', { method: 'POST', data: { file: '' } });
        history.push(`/workspaces/personal/${response.documentID}/`);
        revalidate();
    };

    const createFolder = async () => {
        await fetcher('/api/workspaces', { method: 'POST', data: { directory: '' } });
        revalidate();
    };

    const handleChange = async (props: { dragItem: any, targetPath: number[], items: any[] }) => {
        const { dragItem, targetPath, items } = props;

        // remove the last array item to get the parent folder. Then add 
        // children between each item to get the nested children items.
        const keyPath = targetPath.slice(0, -1).map((dd, index) => {
            if(index === 0) {
                return [dd];
            }

            return ['children', dd];
        }).flat();

        const isTopLevel = keyPath.length === 0;
        const targetID = get(items, [...keyPath, 'id'], null);

        if(!isTopLevel && targetID === null) {
            // not sure why this would happen but we don't want to send the 
            // request if it does
            return;
        }

        await fetcher('/api/workspaces/personal/structure', { 
            method: 'POST', 
            data: {
                fileID: dragItem.id,
                directoryID: isTopLevel ? null : targetID
            },
        });
    }

    const handleConfirmChange = (props: { dragItem: any, destinationParent: any }) => {
        const { dragItem, destinationParent } = props;

        // a null destinationParent means it's a top level destination
        if(!destinationParent || destinationParent.type === 'directory') {
            return true;
        }

        return false;
    };

    const handleDelete = async (e: any, data: any, target: any) => {
        const itemID = target.getAttribute('data-id')

        await fetcher(`/api/workspaces/personal/${itemID}`, { 
            method: 'DELETE', 
            data: {},
        });
    }

    return (
        <div className={css.main}>
            <div className={css.container}>

                <div className={css.workspaces}>
                    <Nestable 
                        maxDepth={10}
                        collapsed={false}
                        items={structure} 
                        onChange={handleChange}
                        confirmChange={handleConfirmChange}
                        renderItem={(props) => renderWorkspaceItem({ item: props.item })} 
                    />
                </div>

                <ContextMenu id="context">
                    <MenuItem onClick={handleDelete}>
                        <div className={css.contextMenuItemContent}>
                            <div>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                            <div>Delete</div>
                        </div>
                    </MenuItem>
                </ContextMenu>

                <div className={css.addWorkspaceButton}>
                    <Button onClick={createDocument} color="primary" emphasis="slight">Add New Document</Button>
                    <Button onClick={createFolder} color="primary" emphasis="slight">Add New Folder</Button>
                </div>
            </div>
        </div>
    );
}

export default Workspace;