// Dependencies
import { createPortal } from 'react-dom';
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState, useRef, useImperativeHandle, PropsWithChildren } from "react";

// Styles
import css from './Modal.sass';

const modalElement = document.createElement('div');
document.body.appendChild(modalElement);

interface ModalProps {
    title: string,
    onClose: () => void,
}

interface BaseProps extends ModalProps {
    className?: string,
}

export const PaddedContent = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    return (
        <div className={css.paddedContent}>
            {children}
        </div>
    );
}

export const BottomRowContent = (props: PropsWithChildren<{}>) => {
    const { children } = props;

    return (
        <div className={css.bottomRow}>
            <PaddedContent>
                {children}
            </PaddedContent>
        </div>
    );
}

const Modal = (props: PropsWithChildren<BaseProps>) => {
    const { title, onClose, children } = props;

    return (
        <div className={css.modal}>
            <div>
                <div className={css.headContainer}>
                    <div className={css.title}>
                        {title}
                    </div>
                    <div className={css.closeButton} onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </div>
                </div>

                <div className={css.bodyContainer}>
                    {children}
                </div>
            </div>
        </div>
    );
}

const ModalPortal = (props: PropsWithChildren<BaseProps>) => {
    const { title, onClose, children } = props;

    const [isOpen, setIsOpen] = useState(true);

    const handleKeyDown = (event: KeyboardEvent) => {
        const { key } = event;

        if(key === 'Escape') {
            onClose();
        }
    }

    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = null;
        }
    }, []);

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [handleKeyDown]);

    return createPortal(
        <div className={css.main}>
            <div className={css.scrollContainer}>
                <Modal
                    title={title}
                    onClose={onClose}
                >{children}</Modal>
            </div>
        </div>,
        modalElement,
    );
};

export default ModalPortal;