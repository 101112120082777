// Dependencies
import React, { useEffect, useRef, useState, StrictMode } from "react";
import useSWR, { SWRConfig } from 'swr';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory,
} from "react-router-dom";
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

// Api
import { fetcher } from "../Api";

// Pages
import Login from './Login';
import AuthenticatedApp from "./Authenticated";

// Components
import Button from '../Components/Button';
import { FullPageSpinner } from '../Components/Spinner';

// Stores
import useAuthStore from "../Stores/authStore";

// styles
import './reset.sass';
import './theme.sass';
import css from './Main.sass';

declare let API_DOMAIN: string;

const MagicLink = () => {
    const history = useHistory();
    const { token } = useParams<{ token: string }>();
    const [setAuthentication] = useAuthStore(state => [state.setAuthentication]);

    useEffect(() => {
        const loginWithToken = async () => {
            const res = await fetcher(`/magic-login/${token}`);
            setAuthentication(res.payload.id, res.payload.email, res.token);
            history.push('/');
        };

        loginWithToken();
    }, []);

    return <FullPageSpinner />
}

const UploadFile = () => {
    const fileRef = useRef(null);
    const [loading, setIsLoading] = useState(false);
    const { token } = useParams<{ token: string }>();

    const onFileChange = async ( event: any ) => {
        setIsLoading(true);
        const data = new FormData();
        const files = event.target.files;

        for(let ii = 0; ii < files.length; ii += 1) {
            data.append('images', files[ii]);
        }

        data.append("token", token);

        try {
            await fetcher(`/api/magic-image-upload`, { method: 'POST', contentType: undefined, formData: data });
            setIsLoading(false);
        }
        catch(err) {
            // 
        }
    }

    const handleButtonClick = () => {
        fileRef.current.click();
    }

    return (
        <div>
            <div className={css.buttonContainer}>
                <input className={css.uploadInput} onChange={onFileChange} ref={fileRef} type="file" accept="image/*" multiple />
                <Button className={css.uploadButton} color="positive" emphasis="high" onClick={handleButtonClick}>Upload</Button>
                {loading ? 'loading' : 'not loading'}
            </div>
        </div>
    )
}

const PublicApp = () => {
    return (
        <Router>
            <Switch>
                <Route path="/magic-login/:token">
                    <MagicLink />
                </Route>

                <Route path="/journal-image-upload/:token">
                    <UploadFile />
                </Route>

                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </Router>
    );
}

const App = () => {
    const { data } = useSWR('/api/me');
    const [isAuthenticated, setAuthentication, logout] = useAuthStore(state => [state.isAuthenticated, state.setAuthentication, state.logout]);

    useEffect(() => {
        if(!data || data?.authenticated === false) {
            logout();
        }
        else if(data?.authenticated) {
            setAuthentication(data.id, data.email, data.token);
        }

    }, [data?.token, data?.id, data?.email]);

    if(data === undefined) {
        return <FullPageSpinner />
    }

    if(isAuthenticated) {
        return <AuthenticatedApp />
    }

    return <PublicApp />;
};

const Setup = () => {
    const fetcher = (resource: string) => {
        const endpoint = `${API_DOMAIN}${resource}`;
        return fetch(endpoint, {
            credentials: 'include',
        }).then(res => res.json())
    };

    return (
        <SWRConfig value={{ fetcher: fetcher }}>
            <MantineProvider>
                <NotificationsProvider>
                    <StrictMode>
                        <App />
                    </StrictMode>
                </NotificationsProvider>
            </MantineProvider>
        </SWRConfig>
    );
}

export default Setup;